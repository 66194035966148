import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import ChiSiamo from './pages/ChiSiamo';
import Corsi from './pages/Corsi';
import Eventi from './pages/Eventi';
import Login from './components/Login';
import AreaRiservata from './components/AreaRiservata';
import PrivateRoute from './components/PrivateRoute';
import setupAxiosInterceptors from './utils/axiosConfig';
import Gallery from './components/Gallery';
import GDPRBanner from './components/GDPRBanner';
import PrivacyPolicy from './pages/PrivacyPolicy';

export const AuthContext = React.createContext();

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      setIsAuthenticated(!!token);
      setIsLoading(false);
      console.log("App - isAuthenticated:", !!token);
    };

    checkAuth();
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      <Router>
        <Layout isLoading={isLoading} isAuthenticated={isAuthenticated} onLogout={handleLogout}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/chi-siamo" component={ChiSiamo} />
            <Route path="/corsi" component={Corsi} />
            <Route path="/eventi" component={Eventi} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/login">
              <Login onLogin={handleLogin} />
            </Route>
            <PrivateRoute 
              path="/area-riservata" 
              component={AreaRiservata} 
              isAuthenticated={isAuthenticated}
            />
          </Switch>
          <GDPRBanner />
        </Layout>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;