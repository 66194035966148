import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LessonList = () => {
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    fetchLessons();
  }, []);

  const fetchLessons = async () => {
    try {
      const response = await axios.get('/api/lessons');
      setLessons(response.data);
    } catch (error) {
      console.error('Errore nel caricamento delle lezioni:', error);
    }
  };

  return (
    <div>
      <h2>Elenco Lezioni</h2>
      <ul>
        {lessons.map(lesson => (
          <li key={lesson.id}>
            <h3>{lesson.title}</h3>
            <p>{lesson.description}</p>
            {lesson.video_url && (
              <a href={lesson.video_url} target="_blank" rel="noopener noreferrer">
                Guarda la lezione
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LessonList;