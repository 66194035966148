import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AttendanceRecord = () => {
  const [attendance, setAttendance] = useState([]);

  useEffect(() => {
    fetchAttendance();
  }, []);

  const fetchAttendance = async () => {
    try {
      const response = await axios.get('/api/attendance');
      setAttendance(response.data);
    } catch (error) {
      console.error('Errore nel caricamento delle presenze:', error);
    }
  };

  return (
    <div>
      <h2>Registro Presenze</h2>
      <table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Lezione</th>
            <th>Presenza</th>
          </tr>
        </thead>
        <tbody>
          {attendance.map(record => (
            <tr key={record.id}>
              <td>{new Date(record.date).toLocaleDateString()}</td>
              <td>{record.lesson_title}</td>
              <td>{record.present ? 'Presente' : 'Assente'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AttendanceRecord;