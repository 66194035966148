import React, { useState, useEffect } from 'react';
import { EyeOff, Moon, ZoomIn, ZoomOut, ArrowUp } from 'lucide-react';

const AccessibilityControls = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [fontSize, setFontSize] = useState(16);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    const savedFontSize = parseInt(localStorage.getItem('fontSize')) || 16;
    
    setIsDarkMode(savedDarkMode);
    setFontSize(savedFontSize);
    
    document.documentElement.classList.toggle('dark', savedDarkMode);
    document.body.style.fontSize = `${savedFontSize}px`;

    const handleScroll = () => {
      setShowScrollTop(window.pageYOffset > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);
    document.documentElement.classList.toggle('dark', newDarkMode);
    localStorage.setItem('darkMode', newDarkMode);
  };

  const increaseFontSize = () => {
    const newSize = Math.min(fontSize + 2, 32);
    setFontSize(newSize);
    document.body.style.fontSize = `${newSize}px`;
    localStorage.setItem('fontSize', newSize);
  };

  const decreaseFontSize = () => {
    const newSize = Math.max(fontSize - 2, 12);
    setFontSize(newSize);
    document.body.style.fontSize = `${newSize}px`;
    localStorage.setItem('fontSize', newSize);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      {/* Versione Desktop - Verticale */}
      <div className="hidden md:flex fixed right-4 top-[50%] z-50 flex-col gap-3">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="accessibility-button"
          aria-label="Controlli accessibilità"
        >
          <EyeOff size={20} color="black" />
        </button>

        {isExpanded && (
          <>
            <button
              onClick={toggleDarkMode}
              className="accessibility-button animate-slide-vertical"
              aria-label={isDarkMode ? 'Attiva modalità chiara' : 'Attiva modalità scura'}
            >
              <Moon size={20} color="black" />
            </button>
            <button
              onClick={increaseFontSize}
              className="accessibility-button animate-slide-vertical delay-100"
              aria-label="Aumenta dimensione testo"
              disabled={fontSize >= 32}
            >
              <ZoomIn size={20} color="black" />
            </button>
            <button
              onClick={decreaseFontSize}
              className="accessibility-button animate-slide-vertical delay-200"
              aria-label="Diminuisci dimensione testo"
              disabled={fontSize <= 12}
            >
              <ZoomOut size={20} color="black" />
            </button>
          </>
        )}
        
        {showScrollTop && (
          <button
            onClick={scrollToTop}
            className="accessibility-button animate-fade-in"
            aria-label="Torna all'inizio"
          >
            <ArrowUp size={20} color="black" />
          </button>
        )}
      </div>

    {/* Versione Mobile - Orizzontale */}
<div className="md:hidden fixed bottom-4 right-4 z-50">
  {isExpanded ? (
    <div className="accessibility-menu animate-fade-in">
      <div className="space-y-2">
        <button onClick={toggleDarkMode} className="accessibility-menu-button">
          <Moon size={20} color="black" />
          <span>Modalità scura</span>
        </button>
        <button 
          onClick={increaseFontSize} 
          className="accessibility-menu-button"
          disabled={fontSize >= 32}
        >
          <ZoomIn size={20} color="black" />
          <span>Testo più grande</span>
        </button>
        <button 
          onClick={decreaseFontSize} 
          className="accessibility-menu-button"
          disabled={fontSize <= 12}
        >
          <ZoomOut size={20} color="black" />
          <span>Testo più piccolo</span>
        </button>
      </div>
      <button 
        onClick={() => setIsExpanded(false)}
        className="absolute -top-2 -right-2 w-6 h-6 bg-white dark:bg-gray-800 rounded-full flex items-center justify-center shadow-md"
      >
        ×
      </button>
    </div>
  ) : (
    <button
      onClick={() => setIsExpanded(true)}
      className="accessibility-floating-button bg-yellow-400 dark:bg-yellow-500" // Aggiunto sfondo giallo
      aria-label="Apri opzioni accessibilità"
    >
      <EyeOff size={24} color="black" />
    </button>
  )}

  {showScrollTop && (
    <button
      onClick={scrollToTop}
      className="accessibility-floating-button bg-yellow-400 dark:bg-yellow-500 animate-fade-in absolute bottom-16 right-0" // Aggiunto sfondo giallo anche qui
      aria-label="Torna all'inizio"
    >
      <ArrowUp size={24} color="black" />
    </button>
  )}
</div>
    </>
  );
};

export default AccessibilityControls;