import React, { useState, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import UserManagement from './UserManagement';
import LessonManagement from './LessonManagement';
import LessonList from './LessonList';
import AttendanceRecord from './AttendanceRecord';

function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div role="alert">
      <p>Qualcosa è andato storto:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Riprova</button>
    </div>
  )
}

const AreaRiservata = () => {
  const [userRole, setUserRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      setIsLoading(true);
      try {
        console.log("Fetching user role...");
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error("Nessun token trovato");
        }
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        if (!decodedToken.role) {
          throw new Error("Ruolo non trovato nel token");
        }
        setUserRole(decodedToken.role);
        console.log("Ruolo utente impostato:", decodedToken.role);
      } catch (error) {
        console.error('Errore nel recupero del ruolo utente:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserRole();
  }, []);

  const renderContent = () => {
    console.log("Rendering content for role:", userRole);
    try {
    switch (userRole) {
      case 'super_admin':
        return (
          <>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <UserManagement />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <LessonManagement />
            </ErrorBoundary>
          </>
        );
      case 'editor':
        return (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <LessonManagement />
          </ErrorBoundary>
        );
      case 'allievo':
        return (
          <>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <LessonList />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <AttendanceRecord />
            </ErrorBoundary>
          </>
        );
      }
    } catch (error) {
      console.error("Errore nel rendering del contenuto:", error);
      return <div>Si è verificato un errore nel caricamento del contenuto.</div>;
    }
  };



  if (isLoading) {
    return <div>Caricamento...</div>;
  }

  if (error) {
    return <div>Errore: {error}</div>;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-aeslis-blue mb-8">Area Riservata</h1>
        {renderContent()}
      </div>
    </ErrorBoundary>
  );
};

export default AreaRiservata;