import React, { useState, useEffect } from 'react';
import { fetchUsers, changeUserRole } from '../utils/api';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUsersData();
  }, []);

  const fetchUsersData = async () => {
    setIsLoading(true);
    try {
      const response = await fetchUsers();
      if (Array.isArray(response.data)) {
        setUsers(response.data);
      } else {
        throw new Error('I dati degli utenti non sono in un formato valido');
      }
      setError(null);
    } catch (error) {
      console.error('Errore nel caricamento degli utenti:', error);
      setError(`Impossibile caricare gli utenti: ${error.message}`);
      setUsers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeUserRole = async (userId, newRole) => {
    try {
      await changeUserRole(userId, newRole);
      await fetchUsersData();
    } catch (error) {
      console.error('Errore nella modifica del ruolo:', error);
      setError(`Impossibile modificare il ruolo: ${error.message}`);
    }
  };

  if (isLoading) return <div>Caricamento utenti...</div>;
  if (error) return <div>Errore: {error}</div>;

  return (
    <div>
      <h2>Gestione Utenti</h2>
      {users.length > 0 ? (
        <ul>
          {users.map(user => (
            <li key={user.id}>
              {user.username} - {user.email} - Ruolo: {user.role}
              <select
                value={user.role}
                onChange={(e) => handleChangeUserRole(user.id, e.target.value)}
              >
                <option value="allievo">Allievo</option>
                <option value="editor">Editor</option>
                <option value="super_admin">Super Admin</option>
              </select>
            </li>
          ))}
        </ul>
      ) : (
        <p>Nessun utente trovato</p>
      )}
    </div>
  );
};

export default UserManagement;