import React, { useState, useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const lightboxRef = useRef(null);

  useEffect(() => {
    const importAll = (r) => {
      return r.keys().map((item) => ({ 
        src: r(item).default,
        name: item.replace('./', '')
      }));
    };

    try {
      const importedImages = importAll(require.context('../assets/images/gallery', false, /\.(png|jpe?g|svg)$/));
      console.log('Imported images:', importedImages);
      setImages(importedImages);
      setIsLoading(false);
    } catch (err) {
      console.error('Error loading images:', err);
      setError('Si è verificato un errore nel caricamento delle immagini: ' + err.message);
      setIsLoading(false);
    }
  }, []);

  const openLightbox = (image) => {
    setSelectedImage(image);
  };

  const closeLightbox = () => {
    setSelectedImage(null);
  };

  const navigateImage = (direction) => {
    const currentIndex = images.findIndex(img => img.src === selectedImage.src);
    const newIndex = (currentIndex + direction + images.length) % images.length;
    setSelectedImage(images[newIndex]);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => navigateImage(1),
    onSwipedRight: () => navigateImage(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  if (isLoading) {
    return <div className="text-center py-12">Caricamento galleria...</div>;
  }

  if (error) {
    return <div className="text-center py-12 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto py-12">
      <h1 className="text-4xl font-bold mb-8 text-center text-aeslis-blue dark:text-yellow-300">Galleria Foto</h1>
      
      {images.length === 0 ? (
        <p className="text-center">Nessuna immagine trovata nella galleria.</p>
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {images.map((image, index) => (
            <div key={index} className="cursor-pointer" onClick={() => openLightbox(image)}>
              <img 
                src={image.src}
                alt={`Immagine galleria ${image.name}`} 
                className="w-full h-48 object-cover rounded-lg shadow-md hover:opacity-80 transition-opacity"
                onError={(e) => {
                  console.error(`Error loading image: ${image.src}`);
                  e.target.onerror = null;
                  e.target.src = '/placeholder-image.jpg';
                }}
              />
            </div>
          ))}
        </div>
      )}

      {selectedImage && (
        <div 
          ref={lightboxRef}
          className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50"
          {...handlers}
        >
          <button 
            onClick={closeLightbox} 
            className="absolute top-4 left-4 text-white text-2xl bg-gray-800 rounded-full w-10 h-10 flex items-center justify-center"
            aria-label="Chiudi lightbox"
          >
            &times;
          </button>
          <button 
            onClick={() => navigateImage(-1)} 
            className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-4xl bg-gray-800 rounded-full w-12 h-12 flex items-center justify-center"
            aria-label="Immagine precedente"
          >
            &#8249;
          </button>
          <img 
            src={selectedImage.src}
            alt={selectedImage.name}
            className="max-h-[80vh] max-w-[80vw] object-contain"
          />
          <button 
            onClick={() => navigateImage(1)} 
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-4xl bg-gray-800 rounded-full w-12 h-12 flex items-center justify-center"
            aria-label="Immagine successiva"
          >
            &#8250;
          </button>
        </div>
      )}
    </div>
  );
};

export default Gallery;