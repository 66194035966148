import React, { useState } from 'react';
import romeoGiuliettaImage from '../assets/images/romeo-giuletta.jpg';

const getYouTubeId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

const VideoModal = ({ isOpen, onClose, video }) => {
  if (!isOpen || !video) return null;

  // Modifica l'URL per incorporare direttamente il video YouTube
  const videoId = getYouTubeId(video.url);
  const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;

  // Previeni la propagazione del click sul contenuto del modal
  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    // Il div esterno che copre tutto lo schermo
    <div 
      className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      {/* Contenitore del modal che ferma la propagazione del click */}
      <div 
        className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-4xl overflow-hidden"
        onClick={handleContentClick}
      >
        {/* Header del modal */}
        <div className="p-4 border-b dark:border-gray-700 flex justify-between items-center">
          <h3 className="text-lg font-bold text-gray-900 dark:text-yellow-300">
            {video.title}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 text-2xl"
            aria-label="Chiudi"
          >
            ×
          </button>
        </div>

        {/* Contenitore del video con aspect ratio 16:9 */}
        <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
          <iframe
            className="absolute top-0 left-0 w-full h-full"
            src={embedUrl}
            title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};

const VideoThumbnail = ({ videoId, title, onClick }) => {
  const [imgSrc, setImgSrc] = useState(`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`);

  const handleImageError = () => {
    if (imgSrc.includes('mqdefault')) {
      setImgSrc(`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`);
    } else if (imgSrc.includes('hqdefault')) {
      setImgSrc(`https://img.youtube.com/vi/${videoId}/default.jpg`);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <div 
      onClick={handleClick}
      className="cursor-pointer group relative rounded-lg overflow-hidden shadow-md bg-gray-100 dark:bg-gray-700"
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') handleClick(e);
      }}
    >
      <img
        src={imgSrc}
        alt={title}
        className="w-full h-48 object-cover transition-transform group-hover:scale-105"
        onError={handleImageError}
      />
      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all">
        <div className="transform scale-0 group-hover:scale-100 transition-transform">
          <svg className="w-16 h-16 text-white" viewBox="0 0 24 24" fill="currentColor">
            <path d="M8 5v14l11-7z" />
          </svg>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 p-3 bg-gradient-to-t from-black to-transparent">
        <p className="text-white text-sm font-medium line-clamp-2">{title}</p>
      </div>
    </div>
  );
};

const EventCard = ({ event }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden mb-8">
      <div className="flex flex-col md:flex-row">
        {event.image && (
          <div className="md:w-1/3 p-4">
            <img 
              src={event.image} 
              alt={event.name} 
              className="w-full rounded-lg shadow-md object-contain" 
            />
          </div>
        )}
        <div className="flex-1 p-6">
          <h3 className="text-2xl font-bold text-aeslis-blue dark:text-yellow-300 mb-4">{event.name}</h3>
          <p className="text-lg text-gray-600 dark:text-gray-300 mb-4 font-semibold">{event.date}</p>
          
          <div className="text-gray-700 dark:text-gray-300 space-y-4">
            {event.description}
          </div>

          {event.cast && (
            <div className="mt-6">
              <h4 className="text-xl font-semibold text-aeslis-blue dark:text-yellow-300 mb-4">Cast</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                {event.cast.map((role, index) => (
                  <p key={index} className="text-gray-700 dark:text-gray-300">
                    <span className="font-bold italic">{role.title}:</span>{' '}
                    <span className="font-medium">{role.actor}</span>
                  </p>
                ))}
              </div>
            </div>
          )}

          {event.videos && (
            <div className="mt-6">
              <h4 className="text-xl font-semibold text-aeslis-blue dark:text-yellow-300 mb-4">
                {event.videos[0].description || 'Video dell\'evento'}
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {event.videos.map((video, index) => (
                  video.parts ? (
                    video.parts.map((part, partIndex) => (
                      <VideoThumbnail
                        key={`${index}-${partIndex}`}
                        videoId={getYouTubeId(part.url)}
                        title={part.title}
                        onClick={() => setSelectedVideo(part)}
                      />
                    ))
                  ) : (
                    <VideoThumbnail
                      key={index}
                      videoId={getYouTubeId(video.url)}
                      title={video.title}
                      onClick={() => setSelectedVideo(video)}
                    />
                  )
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <VideoModal
        isOpen={!!selectedVideo}
        onClose={() => setSelectedVideo(null)}
        video={selectedVideo}
      />
    </div>
  );
};

const Eventi = () => {
  const events = [
    {
      id: 1,
      name: "Romeo & Giulietta - Il Musical in LIS",
      date: "In arrivo nel 2024",
      description: "Un'emozionante interpretazione del classico di Shakespeare in Lingua dei Segni Italiana.",
      image: romeoGiuliettaImage,
      cast: [
        { title: "Regia", actor: "Laura Santarelli" },
        { title: "Aiuto Regia e Coreografie", actor: "Giovanna Rinaldi" },
        { title: "Romeo", actor: "Marco Ceci" },
        { title: "Giulietta", actor: "Angelica Intaschi" },
        { title: "Lady Montecchi", actor: "Moira Sbriccoli" },
        { title: "Lady Capuleti", actor: "Laura Santarelli" },
        { title: "Conte Capuleti", actor: "Carlo Wialletton" },
        { title: "Frate Lorenzo e Conte Montecchi", actor: "Letizia Frattani" },
        { title: "Nutrice", actor: "Gessica Mazzeo" },
        { title: "Tebaldo", actor: "Alessandro Palumbo" }
      ],
      isFuture: true
    },
    {
      id: 2,
      name: "Notre Dame de Paris in LIS",
      date: "2020-2022",
      description: "Il celebre musical Notre Dame de Paris interpretato in Lingua dei Segni Italiana, un progetto innovativo che ha segnato diversi anni di successi. Le rappresentazioni hanno reso accessibile questo capolavoro al pubblico sordo attraverso la potenza espressiva della LIS.",
      videos: [
        {
          description: "Il musical completo in cinque parti:",
          parts: [
            {
              title: "Notre Dame de Paris - Parte 1: Il Tempo delle Cattedrali",
              url: "https://youtu.be/DgL9-Oi1m1Q"
            },
            {
              title: "Notre Dame de Paris - Parte 2: Belle",
              url: "https://youtu.be/HQeBwuX5a-M"
            },
            {
              title: "Notre Dame de Paris - Parte 3: La Festa dei Folli",
              url: "https://youtu.be/_r8nslFWJ1U"
            },
            {
              title: "Notre Dame de Paris - Parte 4: Danza mia Esmeralda",
              url: "https://youtu.be/6tKon7kvJVc"
            },
            {
              title: "Notre Dame de Paris - Parte 5: Luna",
              url: "https://youtu.be/nPBrdof2i8Q"
            }
          ]
        }
      ],
      isFuture: false
    },
    {
      id: 3,
      name: "Inno di Mameli in LIS",
      date: "2022",
      description: "L'Inno Nazionale Italiano interpretato in Lingua dei Segni Italiana, un progetto che unisce cultura, identità e inclusività. Questa interpretazione rende accessibile uno dei simboli più importanti della nostra nazione alla comunità sorda.",
      videos: [
        {
          title: "Inno di Mameli in LIS",
          url: "https://youtu.be/0ynVd9f5suM"
        }
      ],
      isFuture: false
    }
  ];

  const futureEvents = events.filter(event => event.isFuture);
  const pastEvents = events.filter(event => !event.isFuture);

  return (
    <div className="container mx-auto py-12 px-4 dark:bg-gray-900">
      <h1 className="text-4xl font-bold text-center text-aeslis-blue dark:text-yellow-300 mb-8">Eventi AES</h1>
      
      <h2 className="text-2xl font-bold text-aeslis-blue dark:text-yellow-300 mb-6">Eventi Futuri</h2>
      {futureEvents.length > 0 ? (
        futureEvents.map(event => <EventCard key={event.id} event={event} />)
      ) : (
        <p className="text-gray-700 dark:text-gray-300">Nessun evento futuro al momento.</p>
      )}

      <h2 className="text-2xl font-bold text-aeslis-blue dark:text-yellow-300 mt-12 mb-6">Eventi Passati</h2>
      {pastEvents.length > 0 ? (
        pastEvents.map(event => <EventCard key={event.id} event={event} />)
      ) : (
        <p className="text-gray-700 dark:text-gray-300">Nessun evento passato.</p>
      )}
    </div>
  );
};

export default Eventi;