// src/pages/PrivacyPolicy.jsx
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-12 dark:bg-gray-900">
      <h1 className="text-3xl font-bold mb-6 text-aeslis-blue dark:text-yellow-300">
        Privacy Policy
      </h1>
      
      <div className="prose dark:prose-invert max-w-none">
        <p className="mb-4 text-gray-700 dark:text-gray-300">
          Ultimo aggiornamento: {new Date().toLocaleDateString()}
        </p>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-aeslis-blue dark:text-yellow-300">Titolare del Trattamento</h2>
          <p className="text-gray-700 dark:text-gray-300">
            AES Accademia Europea Sordi Onlus<br />
            Email: aesformazione@gmail.com<br />
            Indirizzo: Via Nomentana 56, 00161 Roma (RM)
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-aeslis-blue dark:text-yellow-300">Dati Raccolti</h2>
          <p className="text-gray-700 dark:text-gray-300">
            Il sito raccoglie automaticamente i seguenti dati:
          </p>
          <ul className="list-disc pl-5 text-gray-700 dark:text-gray-300">
            <li>Cookie tecnici necessari al funzionamento del sito</li>
            <li>Dati di navigazione anonimi per fini statistici</li>
            <li>Dati forniti volontariamente dall'utente attraverso form di contatto</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-aeslis-blue dark:text-yellow-300">Finalità del Trattamento</h2>
          <p className="text-gray-700 dark:text-gray-300">
            I dati vengono raccolti per:
          </p>
          <ul className="list-disc pl-5 text-gray-700 dark:text-gray-300">
            <li>Consentire la corretta navigazione del sito</li>
            <li>Rispondere alle richieste degli utenti</li>
            <li>Migliorare l'esperienza di navigazione</li>
            <li>Adempiere ad obblighi di legge</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4 text-aeslis-blue dark:text-yellow-300">Diritti dell'Utente</h2>
          <p className="text-gray-700 dark:text-gray-300">
            Ai sensi del GDPR, l'utente ha diritto di:
          </p>
          <ul className="list-disc pl-5 text-gray-700 dark:text-gray-300">
            <li>Accedere ai propri dati personali</li>
            <li>Ottenerne la rettifica o la cancellazione</li>
            <li>Limitarne il trattamento</li>
            <li>Opporsi al trattamento</li>
            <li>Richiedere la portabilità dei dati</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;