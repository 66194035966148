import React, { useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Menu, X, LogOut } from 'lucide-react';

const Header = ({ isAuthenticated, onLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const isActive = (path) => location.pathname === path ? 'active' : '';

  const handleLogout = () => {
    onLogout();
    history.push('/');
  };

  return (
    <header className="bg-aeslis-blue text-white shadow-md relative">
      <div className="container mx-auto px-4 py-4">
      <div className="flex justify-between items-center">
  <Link to="/" className="flex items-center space-x-2">
    <img src="logo192.png" alt="Logo" className="h-8 w-8" />
    <span className="text-2xl font-bold">AES </span>
  </Link>
  <div className="md:hidden">
    <button onClick={toggleMenu} aria-label="Toggle menu">
      {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
    </button>
  </div>


          <nav className={`md:flex ${isMenuOpen ? 'block' : 'hidden'} absolute md:relative left-0 md:left-auto top-full md:top-auto w-full md:w-auto bg-aeslis-blue md:bg-transparent z-50`}>
            <ul className="flex flex-col md:flex-row md:items-center md:space-x-4">
              <li><Link to="/" className={`nav-link block py-2 px-4 md:px-2 ${isActive('/')}`} onClick={toggleMenu}>Home</Link></li>
              <li><Link to="/chi-siamo" className={`nav-link block py-2 px-4 md:px-2 ${isActive('/chi-siamo')}`} onClick={toggleMenu}>Chi Siamo</Link></li>
              <li><Link to="/corsi" className={`nav-link block py-2 px-4 md:px-2 ${isActive('/corsi')}`} onClick={toggleMenu}>Corsi</Link></li>
              <li><Link to="/eventi" className={`nav-link block py-2 px-4 md:px-2 ${isActive('/eventi')}`} onClick={toggleMenu}>Eventi</Link></li>
              <li><Link to="/gallery" className={`nav-link block py-2 px-4 md:px-2 ${isActive('/gallery')}`} onClick={toggleMenu}>Galleria</Link></li>
              {/* <li>
                <Link 
                  to="/area-riservata" 
                  className={`nav-link block py-2 px-4 md:px-2 ${isActive('/area-riservata')}`} 
                  onClick={toggleMenu}
                >
                  Area Riservata
                </Link>
              </li>
              {isAuthenticated && (
                <li>
                  <button 
                    onClick={handleLogout} 
                    className="nav-link block py-2 px-4 md:px-2 text-left w-full md:w-auto items-center"
                  >
                    <LogOut size={18} className="mr-1" /> Logout
                  </button>
                </li> 
              )}*/}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;