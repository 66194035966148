import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '/api', // Usa una variabile d'ambiente se possibile
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const fetchUsers = () => api.get('/users');
export const changeUserRole = (userId, newRole) => api.put(`/users/${userId}/role`, { role: newRole });
export const fetchLessons = () => {
  console.log('Fetching lessons from:', `${api.defaults.baseURL}/lessons`);
  return api.get('/lessons');
};export const fetchAttendance = (userId) => api.get(`/attendance/${userId}`);

export default api;