// src/components/GDPRBanner.jsx
import React, { useState, useEffect } from 'react';

const GDPRBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('gdprConsent');
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('gdprConsent', 'true');
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 shadow-lg z-50">
      <div className="container mx-auto px-4 py-4 flex flex-col md:flex-row items-center justify-between">
        <div className="text-sm text-gray-700 dark:text-gray-300 mb-4 md:mb-0 md:mr-4">
          Questo sito web utilizza i cookie per migliorare la tua esperienza di navigazione. AES Accademia Europea Sordi Onlus rispetta la tua privacy; puoi trovare maggiori informazioni su come utilizziamo i cookie nella nostra Privacy Policy.
        </div>
        <div className="flex gap-4">
          <a 
            href="/privacy-policy" 
            className="text-sm text-blue-600 dark:text-yellow-400 hover:underline"
          >
            Privacy Policy
          </a>
          <button
            onClick={handleAccept}
            className="bg-blue-600 dark:bg-yellow-400 text-white dark:text-black px-4 py-2 rounded hover:bg-blue-700 dark:hover:bg-yellow-500 transition-colors text-sm font-medium"
          >
            Accetto
          </button>
        </div>
      </div>
    </div>
  );
};

export default GDPRBanner;