import React from 'react';
import { Facebook, Instagram, Youtube } from 'lucide-react';
import TikTokIcon from './TikTokIcon'; 
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa';


const Footer = () => {
  return (
    <footer className="bg-aeslis-blue text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-xl font-bold mb-2">AES</h3>
            <p>Scuola di Lingua dei Segni Italiana</p>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
          <h4 className="text-lg font-semibold mb-4">Segreteria AES</h4>
          
          <div className="flex flex-col space-y-3">
            <a 
              href="mailto:aesformazione@gmail.com"
              className="flex items-center space-x-2 hover:text-yellow-400 transition-colors"
            >
              <FaEnvelope size={20} />
              <span>aesformazione@gmail.com</span>
            </a>
            
            <a 
              href="https://wa.me/393775980289" 
              target="_blank" 
              rel="noopener noreferrer"  
              className="flex items-center space-x-2 hover:text-yellow-400 transition-colors"
            >
              <FaWhatsapp size={20} />
              <span>+39 377 598 0289</span>
            </a>
          </div>
        </div>
          <div className="w-full md:w-1/3">
          <h4 className="text-lg font-semibold mb-2">Seguici</h4>
          <div className="flex space-x-4">
            <a href="#" target="_blank" className="social-icon hover:text-yellow-400 transition-colors">
              <Facebook size={24} />
              <span className="sr-only">Facebook</span>
            </a>
            <a href="https://www.instagram.com/accademiaeuropeasordi?igsh=MXN1bmpsdTA2aDdzaw==" target="_blank" className="social-icon hover:text-yellow-400 transition-colors">
              <Instagram size={24} />
              <span className="sr-only">Instagram</span>
            </a>
            <a href="https://www.tiktok.com/@aes.accademia.eur?_t=8qZbvwTMQTJ&_r=1" target="_blank" className="social-icon hover:text-yellow-400 transition-colors">
              <TikTokIcon size={24} />
              <span className="sr-only">TikTok</span>
            </a>
          </div>
        </div>
        </div>
        <div className="mt-8 text-center">
          <p>&copy; 2024 AESLIS. Tutti i diritti riservati.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;