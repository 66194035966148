import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const CourseCard = ({ level, title, description, details }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-6">
      <h3 className="text-2xl font-bold text-aeslis-blue dark:text-yellow-300 mb-3">{level}: {title}</h3>
      <p className="text-gray-700 dark:text-gray-300 mb-4">{description}</p>
      <button 
        onClick={() => setIsExpanded(!isExpanded)}
        className="inline-flex items-center bg-aeslis-blue dark:bg-yellow-400 text-white dark:text-black font-bold py-2 px-4 rounded hover:bg-blue-700 dark:hover:bg-yellow-500 transition-colors"
      >
        {isExpanded ? 'Chiudi' : 'Scopri di più'}
        {isExpanded ? <FaChevronUp className="ml-2 dark:text-black" /> : <FaChevronDown className="ml-2 dark:text-black" />}
      </button>
      {isExpanded && (
        <div className="mt-4 dark:bg-yellow-400 dark:text-black p-4 rounded">
          {details}
        </div>
      )}
    </div>
  );
};

const Corsi = () => {
  const courses = [
    {
      level: "A1",
      title: "Livello BASE",
      description: "Corso per principianti assoluti. Imparerai le basi della Lingua dei Segni Italiana, l'alfabeto manuale e i primi segni per comunicare concetti semplici.",
      details: (
        <>
          <h4 className="font-bold mb-2">Contenuti del corso:</h4>
          <p>Ognuna delle sei unità didattiche del Primo Livello si focalizza su un diverso tema di base:</p>
          <ul className="list-disc pl-5 mb-4">
            <li>Unità 1: Come presentarsi</li>
            <li>Unità 2: Scambio di informazioni personali</li>
            <li>Unità 3: Descrizione dell'ambiente</li>
            <li>Unità 4: Parlare di dove abiti</li>
            <li>Unità 5: Parlare della famiglia</li>
            <li>Unità 6: Vita quotidiana: cosa facciamo e quando</li>
          </ul>
          <h5 className="font-bold mt-4 mb-2">Parte Linguistica teorica:</h5>
          <ul className="list-disc pl-5 mb-4">
            <li>I segni Nome</li>
            <li>La frase minima</li>
            <li>Le frasi affermative, negative, interrogative</li>
            <li>Alcuni segni idiomatici</li>
            <li>Lo spazio</li>
            <li>I parametri</li>
            <li>Gli studi e le ricerche condotte</li>
            <li>La regola dei numeri</li>
          </ul>
          <h5 className="font-bold mt-4 mb-2">Parte antropologica culturale:</h5>
          <ul className="list-disc pl-5 mb-4">
            <li>La persona sorda</li>
            <li>La cultura sorda</li>
            <li>Storia dell'educazione: gli Istituti</li>
            <li>Le Associazioni</li>
            <li>Le lotte per i diritti: le leggi e le convenzioni</li>
            <li>La LIS come movimento politico e culturale</li>
            <li>L'Ente Nazionale Sordi</li>
            <li>I circoli ricreativi</li>
          </ul>
        </>
      )
    },
    {
      level: "A2",
      title: "Livello INTERMEDIO",
      description: "Questo corso è pensato per chi ha già una conoscenza base della LIS. Approfondirai la grammatica, amplierai il tuo vocabolario e migliorerai la tua fluidità nella conversazione.",
      details: (
        <>
          <h4 className="font-bold mb-2">Contenuti del corso:</h4>
          <ul className="list-disc pl-5 mb-4">
            <li>Parlare della Famiglia e del Lavoro</li>
            <li>Come raggiungere il proprio lavoro o luogo di studio</li>
            <li>Attenzione ai classificatori: Muoversi con i mezzi o a piedi, o auto e moto</li>
            <li>Grammatica LIS: Frasi con avverbi di luogo, tempo e quantità</li>
            <li>Descrivere gli altri: abbigliamento, comportamento, carattere, tratti somatici, lavoro</li>
            <li>Umorismo sordo: Barzellette inerenti cultura sorda</li>
            <li>Esercizi di memoria visiva</li>
            <li>Dialoghi su informazioni personali e carattere</li>
            <li>Vita quotidiana: spesa, lavoro, sport, figli, scuola, Università, hobbie, viaggi, sogni, desideri</li>
            <li>Storytelling: Favola di Pinocchio per immagini</li>
            <li>Uso dei verbi in vari contesti</li>
            <li>Numeri ordinali, cardinali, incorporati</li>
          </ul>
          <h5 className="font-bold mt-4 mb-2">Aspetti culturali e legislativi:</h5>
          <ul className="list-disc pl-5 mb-4">
            <li>La legge 104 e i suoi vantaggi</li>
            <li>La legge 68/99</li>
            <li>La convenzione ONU</li>
            <li>Riconoscimento della LIS</li>
            <li>Educazione bilingue e oralista</li>
            <li>Impianto cocleare, Protesi</li>
            <li>Differenze tra sordi oralisti e segnanti</li>
            <li>L'accessibilità: importanza e ambiti principali</li>
          </ul>
          <p><strong>Il corso include esercizi e verifiche per gli studenti.</strong></p>
        </>
      )
    },
    {
      level: "B1",
      title: "Livello AVANZATO",
      description: "Corso di livello avanzato per approfondire la conoscenza della LIS e migliorare le competenze comunicative.",
      details: (
        <>
          <h4 className="font-bold mb-2">Contenuti del corso:</h4>
          <p>Il corso si concentra sul ripasso globale del metodo VISTA, unità 13-17, e include i seguenti argomenti:</p>
          <ul className="list-disc pl-5 mb-4">
            <li>Lamentarsi, consigliare, richiedere</li>
            <li>Descrivere ed identificare</li>
            <li>Arredamento della casa</li>
            <li>Parlare del fine settimana</li>
            <li>Scambio di informazioni personali: gli eventi della vita</li>
          </ul>
          <h5 className="font-bold mt-4 mb-2">Argomenti specifici:</h5>
          <ul className="list-disc pl-5 mb-4">
            <li>Vocabolario specifico per malesseri, lamentele, arredamento, attività del tempo libero</li>
            <li>Classificatori descrittivi e strumentali</li>
            <li>Segni di tempo ricorrenti e continui</li>
            <li>Esercizi su numeri dell'orologio e alternanza dei ruoli</li>
            <li>Descrivere la disposizione delle stanze nella casa</li>
            <li>Raccontare eventi e brevi gite</li>
            <li>Albero genealogico e storia della famiglia</li>
          </ul>
        </>
      )
    },
    {
      level: "Specializzazione",
      title: "Corso Assistente alla Comunicazione",
      description: "Corso specializzato per formare Assistenti alla Comunicazione, figure professionali cruciali per l'inclusione scolastica dei sordi.",
      details: (
        <>
          <h4 className="font-bold mb-2">Aree di studio:</h4>
          <ul className="list-disc pl-5 mb-4">
            <li>Area psico-pedagogica</li>
            <li>Area linguistica</li>
            <li>Area istituzionale-legislativa</li>
            <li>Area tecnico-professionale</li>
          </ul>
          <h5 className="font-bold mt-4 mb-2">Argomenti principali:</h5>
          <ul className="list-disc pl-5 mb-4">
            <li>Pedagogia speciale e psicologia dell'età evolutiva</li>
            <li>Sviluppo del linguaggio e famiglia e sordità</li>
            <li>Diagnosi Funzionale, PEI e GLO</li>
            <li>Bisogni Educativi Speciali e disturbi specifici dell'apprendimento</li>
            <li>Comunicazione Aumentativa Alternativa</li>
            <li>Lingua dei Segni Italiana: interpretazione e traduzione</li>
            <li>Legislazione scolastica sull'inclusione</li>
            <li>Ruolo dell'Assistente alla Comunicazione</li>
          </ul>
          <p><strong>Il corso include lezioni teorico-pratiche e tirocinio formativo.</strong></p>
        </>
      )
    }
  ];

  return (
    <div className="container mx-auto px-4 py-12 dark:bg-gray-900">
      <h1 className="text-4xl font-bold text-aeslis-blue dark:text-yellow-300 mb-8 text-center">I nostri corsi</h1>
      <p className="text-lg text-gray-700 dark:text-gray-300 mb-8 text-center">
        Scopri il percorso formativo AES per diventare un esperto della Lingua dei Segni Italiana.
      </p>

      {/* Griglia dei corsi */}
      <div className="grid md:grid-cols-2 gap-6">
        {courses.map((course) => (
          // Renderizza ciascun corso come CourseCard
          // Utilizza il destructuring per passare le proprietà direttamente come props
          
< CourseCard key={course.level} {...course} />
))}
      </div>

    </div >
);
};

export default Corsi;
