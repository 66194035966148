import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import homeImage from '../assets/images/home1.jpg';
import logo from '../assets/images/logo192.png';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>AES - Scuola Interprete LIS e Performer</title>
        <meta name="description" content="AES offre corsi di Lingua dei Segni Italiana per interpreti e performer. Scopri i nostri programmi di formazione professionale." />
        <meta name="keywords" content="LIS, Lingua dei Segni Italiana, interprete, performer, corsi, AES" />
        <link rel="canonical" href="https://aeslis.eu/" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "EducationalOrganization",
              "name": "AES - Scuola Interprete LIS e Performer",
              "url": "https://aeslis.eu",
              "description": "Scuola di formazione per interpreti LIS e performer",
              "address": {
                "@type": "PostalAddress",
                "addressCountry": "IT"
              }
            }
          `}
        </script>
      </Helmet>

    <div className="container mx-auto py-12">
      <section className="text-center mb-16">
      <img src={logo} alt="AES Logo" className="mx-auto mb-8 w-32 h-32" /> {/* Aggiungi questa linea */}
        <h1 className="text-5xl font-bold text-aeslis-blue dark:text-yellow-300 mb-4">Benvenuti in AES</h1>
        <h2 className="text-4xl font-bold text-aeslis-blue dark:text-yellow-300 mb-4">Scuola Interprete LIS e Performer</h2>
        <p className="text-2xl mb-8 text-gray-700 dark:text-yellow-200">La tua porta d'accesso al mondo della Lingua dei Segni Italiana</p>
        <Link to="/corsi" className="cta-button">
          Scopri i nostri corsi
        </Link>
      </section>

      <section className="grid md:grid-cols-3 gap-8 mb-16">
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 text-aeslis-blue dark:text-yellow-300">Corsi per tutti</h2>
          <p className="text-gray-700 dark:text-yellow-200">Offriamo corsi per principianti, intermedi e avanzati. Trova il livello perfetto per te.</p>
        </div>
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 text-aeslis-blue dark:text-yellow-300">Docenti qualificati</h2>
          <p className="text-gray-700 dark:text-yellow-200">I nostri docenti sono esperti madrelingua LIS con anni di esperienza nell'insegnamento.</p>
        </div>
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4 text-aeslis-blue dark:text-yellow-300">Ambiente inclusivo</h2>
          <p className="text-gray-700 dark:text-yellow-200">Creiamo un'atmosfera accogliente e inclusiva per tutti i nostri studenti.</p>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 md:pr-8 mb-6 md:mb-0">
            <h2 className="text-3xl font-bold mb-6 text-aeslis-blue dark:text-yellow-300">Perché scegliere AES?</h2>
            <p className="text-xl mb-8 text-gray-700 dark:text-yellow-200">
              AES è più di una semplice scuola. Siamo una comunità dedicata a promuovere la cultura sorda e 
              a costruire ponti di comunicazione. Unisciti a noi nel nostro viaggio per rendere il mondo più accessibile e inclusivo.
            </p>
            <Link to="/chi-siamo" className="text-aeslis-blue dark:text-yellow-300 font-semibold hover:text-aeslis-gold dark:hover:text-yellow-400 transition-colors">
              Scopri di più su di noi →
            </Link>
          </div>
          <div className="md:w-1/2 flex justify-center">
            <img src={homeImage} alt="Home" className="w-full md:w-4/5 rounded-lg shadow-md" />
          </div>
        </div>
      </section>
    </div>
    </>
  );
};

export default Home;