import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import AccessibilityControls from './AccessibilityControls';

const Layout = ({ children, isAuthenticated, onLogout }) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsTransitioning(true);
    const timer = setTimeout(() => setIsTransitioning(false), 300);
    return () => clearTimeout(timer);
  }, [location]);

  const renderLoader = () => (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-aeslis-blue"></div>
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen">
      <Header isAuthenticated={isAuthenticated} onLogout={onLogout} />
      <AccessibilityControls />
      <main className="flex-grow">
        {isTransitioning ? renderLoader() : children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;