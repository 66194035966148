import React, { useState, useEffect } from 'react';
import { fetchLessons } from '../utils/api';

const LessonManagement = () => {
  const [lessons, setLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchLessonsData();
  }, []);

  const fetchLessonsData = async () => {
    setIsLoading(true);
    try {
      const response = await fetchLessons();
      console.log('Risposta API lezioni:', response);

      if (typeof response.data === 'string' && response.data.trim().toLowerCase().startsWith('<!doctype html>')) {
        throw new Error('Ricevuto HTML invece di JSON. La richiesta API potrebbe essere configurata incorrettamente.');
      }

      let parsedData = response.data;
      if (typeof parsedData === 'string') {
        try {
          parsedData = JSON.parse(parsedData);
        } catch (parseError) {
          console.error('Errore nel parsing della stringa JSON:', parseError);
          throw new Error('Impossibile parsare i dati delle lezioni: formato non valido');
        }
      }

      if (Array.isArray(parsedData)) {
        setLessons(parsedData);
      } else if (typeof parsedData === 'object' && parsedData !== null) {
        const lessonsArray = Object.values(parsedData).find(Array.isArray);
        setLessons(lessonsArray || [parsedData].filter(item => item.id && item.title));
      } else {
        throw new Error(`Formato dati lezioni non valido: ${typeof parsedData}`);
      }
      setError(null);
    } catch (error) {
      console.error('Errore nel caricamento delle lezioni:', error);
      setError(`Impossibile caricare le lezioni: ${error.message}`);
      setLessons([]);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div>Caricamento lezioni...</div>;
  if (error) return <div>Errore: {error}</div>;

  return (
    <div>
      <h2>Gestione Lezioni</h2>
      {lessons.length > 0 ? (
        <ul>
          {lessons.map(lesson => (
            <li key={lesson.id}>
              <h3>{lesson.title}</h3>
              <p>{lesson.description}</p>
              {lesson.video_url && (
                <a href={lesson.video_url} target="_blank" rel="noopener noreferrer">
                  Video della lezione
                </a>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p>Nessuna lezione trovata</p>
      )}
    </div>
  );
};

export default LessonManagement;