import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import lauraSantarelliImage from '../assets/images/laura-santarelli.jpg';
import tizianaDichiaraImage from '../assets/images/tiziana_di_chiara.jpg';
import moiraSbriccoliImage from '../assets/images/sbriccoli_moira.jpg';
import carloLoiImage from '../assets/images/carlo-loi.jpg';
import mariaRizzoImage from '../assets/images/maria-rizzo.jpg';
import carloWiallettonImage from '../assets/images/carlo-wialletton.jpg';
import antonioDiMarcoImage from '../assets/images/antonio-dimarco.jpg';
import umbertoStefaniniImage from '../assets/images/umberto_stefanini.jpg';



const ExpandableSection = ({ title, content, image }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="mb-6 border rounded-lg shadow-md dark:border-yellow-400">
      <button
        className={`w-full flex items-center justify-between p-4 font-bold text-left transition-colors ${
          isExpanded 
            ? 'bg-black text-yellow-400 dark:bg-black dark:text-yellow-400' 
            : 'bg-gray-100 hover:bg-gray-200 dark:bg-yellow-400 dark:hover:bg-yellow-500 text-black dark:text-black'
        }`}
        onClick={() => setIsExpanded(!isExpanded)}
        aria-expanded={isExpanded}
      >
        <span>{title}</span>
        {isExpanded ? (
          <FaMinus className="text-yellow-400" />
        ) : (
          <FaPlus className="text-black dark:text-black" />
        )}
      </button>
      {isExpanded && (
        <div className="p-4 bg-white dark:bg-black">
          <div className="md:flex">
            <img src={image} alt={title} className="w-full md:w-1/3 rounded-lg mb-4 md:mb-0 md:mr-4" />
            <div className="md:w-2/3">
              <p className="text-black dark:text-yellow-400">{content}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ChiSiamo = () => {
  const direttrice = {
    title: "Direttrice della Scuola AES - Santarelli Laura",
    content: (
      <>
        <p>Santarelli Laura nasce in una famiglia di Sordi ed è bilingue dalla nascita. Interprete e Performer LIS, è anche formatrice.</p>
        <ul className="list-disc pl-5 space-y-2 mt-3">
          <li>Pioniera della figura del Performer LIS, professione nata in Italia grazie alla sua intuizione nel 1994.</li>
          <li>Ha collaborato con varie Università, tra cui Tor Vergata di Roma.</li>
          <li>Direttrice della Scuola dal 2008, porta avanti gli insegnamenti del padre e l'esperienza maturata in vari ambiti, incluso quello televisivo.</li>
          <li>Ha lavorato per 28 anni in RAI:
            <ul className="list-circle pl-5 mt-1">
              <li>-Molti anni al Tg1 per l'edizione LIS.</li>
              <li>-Consulente per RAI Pubblica Utilità, promuovendo e ideando nuovi format televisivi come autrice.</li>
              <li>-Ideatrice del Sanremo LIS accessibile con sottotitoli e uso dei Performer LIS.</li>
            </ul>
          </li>
          <li>Attualmente docente presso AES e l'Università SSLM San Domenico di Fermo.</li>
          <li>Consulente per l'accessibilità per varie aziende, tra cui BNL Paribas.</li>
          <li>Regista teatrale specializzata in opere musicali:
            <ul className="list-circle pl-5 mt-1">
              <li>-Realizzazione più importante: Notre Dame de Paris in versione LIS.</li>
              <li>-Direttrice artistica di cinque opere musicali per Rai Cultura.</li>
            </ul>
          </li>
        </ul>
      </>
    ),
        image: lauraSantarelliImage
  };

  const docenti = [
    
    {
      title: "Maria Rizzo - Docente e Referente Area Pedagogica",
      content: (
        <>
          <p>Maria Rizzo è una professionista specializzata nell'ambito pedagogico ed educativo, con una vasta esperienza nel campo dell'interpretariato LIS e dei servizi socio-educativi.</p>
          <ul className="list-disc pl-5 space-y-2 mt-3">
            <li>Formazione:
              <ul className="list-circle pl-5 mt-1">
                <li>Laurea in Educatore Professionale di Comunità</li>
                <li>Laureanda magistrale in Coordinatore di Servizi Educativi e dei Servizi Sociali</li>
                <li>Master in Pedagogia Speciale ed Interculturale</li>
                <li>Master in Accoglienza Richiedenti Asilo e Rifugiati</li>
                <li>Diploma di Specializzazione in Cooperazione Internazionale</li>
              </ul>
            </li>
            <li>Esperienze professionali:
              <ul className="list-circle pl-5 mt-1">
                <li>Interprete LIS per istituzioni pubbliche e private</li>
                <li>Coordinatrice di servizi socio-educativi per minori</li>
                <li>Educatrice Professionale presso comunità educative</li>
                <li>Missioni Internazionali per Progetti Umanitari</li>
                <li>Referente area pedagogica del corso di specializzazione in Assistente alla Comunicazione LIS presso Accademia Europe Sordi</li>
                <li>Docente dell'Accademia Europea Sordi Onlus dal 2015</li>
              </ul>
            </li>
          </ul>
        </>
      ),
      image: mariaRizzoImage
    },
    {
      title: "Tiziana Di Chiara - Docente LIS",
      content: (
        <>
        <p>Tiziana De Chiara è una professionista esperta nel campo della Lingua dei Segni Italiana (LIS) e dell'educazione delle persone sorde.</p>
        <ul class="list-disc pl-5 space-y-2 mt-3">
          <li>Laureata in Scienze dell'Educazione</li>
          <li>Assistente alla comunicazione nelle scuole di ogni ordine e grado</li>
          <li>Mediatrice culturale presso la Diaconia Valdese</li>
          <li>Interprete e performer LIS per l'accessibilità a tutto tondo delle persone sorde</li>
          <li>Docente di Lingua dei Segni Italiana presso l'Accademia Europea Sordi Onlus dal 2018</li>
          <li>Esperienza in vari livelli di insegnamento della LIS</li>
        </ul>
      </>
      ),
      image: tizianaDichiaraImage
    },

    {
      title: "Carlo Wialletton - Docente LIS",
      content: (
        <>
          <p>Figlio di genitori sordi, è nato e cresciuto nel mondo e nella cultura dei sordi, sviluppando una profonda comprensione della comunità sorda e della LIS.</p>
          <ul className="list-disc pl-5 space-y-2 mt-3">
            <li>Formazione e ruoli:
              <ul className="list-circle pl-5 mt-1">
                <li>Interprete e performer LIS dal 2008, formato nell'AES Accademia Europea Sordi</li>
                <li>Docente nei corsi dei vari livelli LIS</li>
                <li>Docente nel corso Interpreti e performer LIS della AES</li>
              </ul>
            </li>
            <li>Esperienze professionali:
              <ul className="list-circle pl-5 mt-1">
                <li>Esperienza decennale in servizi di Interpretariato per:
                  <ul className="list-circle pl-5 mt-1">
                    <li>Istituzioni pubbliche</li>
                    <li>Istituzioni private</li>
                    <li>Utenti sordi</li>
                  </ul>
                </li>
                <li>Assistente alla comunicazione nelle scuole medie e superiori</li>
                <li>Attore e performer LIS</li>
              </ul>
            </li>
          </ul>
        </>
      ),
      image: carloWiallettonImage
    },
    
    {
      title: "Carlo Loi - Docente LIS",
      content: (
        <>
        <p>Carlo Loi è un docente madrelingua LIS con una profonda esperienza nell'insegnamento e nella cultura sorda.</p>
        <ul class="list-disc pl-5 space-y-2 mt-3">
          <li>Madrelingua LIS e nato sordo</li>
          <li>Docente LIS di lunga esperienza presso la Scuola AES</li>
          <li>Formazione specifica:
            <ul class="list-circle pl-5 mt-1">
              <li>Vari corsi di formazione per docenti LIS</li>
              <li>Specializzazione nell'insegnamento della LIS</li>
            </ul>
          </li>
          <li>Ruoli chiave:
            <ul class="list-circle pl-5 mt-1">
              <li>Affiancamento ai docenti interpreti LIS nel corso Interpreti LIS e Performer</li>
              <li>Consulente LIS per progetti di accessibilità</li>
            </ul>
          </li>
        </ul>
      </>
    ),
            image: carloLoiImage  // Assicurati di avere l'immagine e di importarla correttamente
    },
   
    {
      title: "Moira Sbriccoli - Docente LIS",
      content: (
        <>
        <p>Figlia di genitori sordi, sono nata e cresciuta nel mondo e nella cultura dei sordi.</p>
        <ul class="list-disc pl-5 space-y-2 mt-3">
          <li>Interprete e performer LIS dal 2016, formata nell'AES Accademia Europea Sordi</li>
          <li>Esperienza decennale in servizi di Interpretariato per istituzioni pubbliche e private</li>
          <li>Coordinatrice di servizi di interpretariato LIS per:
            <ul class="list-circle pl-5 mt-1">
              <li>Istituzioni pubbliche</li>
              <li>Convegni</li>
              <li>Eventi</li>
            </ul>
          </li>
          <li>Collaborazioni con Laura Santarelli:
            <ul class="list-circle pl-5 mt-1">
              <li>Assistente della direttrice artistica e autrice per il primo Sanremo Live LIS su Raiplay</li>
              <li>Partecipazione alla seconda edizione del 2021</li>
            </ul>
          </li>
          <li>Abilitazione di formatrice nel settore della lingua dei segni italiana conseguita presso AES</li>
          <li>Docente nel corso Interpreti e performer LIS della AES</li>
        </ul>
      </>
      ),
      image: moiraSbriccoliImage 
    },
    {
      title: "Antonio Di Marco - Docente LIS",
      content: (
        <>
          <p>Antonio Di Marco è un esperto docente LIS con una vasta formazione accademica e un'esperienza di insegnamento che dura dal 2006.</p>
          <ul className="list-disc pl-5 space-y-2 mt-3">
            <li>Formazione Accademica:
              <ul className="list-circle pl-5 mt-1">
                <li>Laurea in Scienze dell'Educazione</li>
                <li>Laurea Magistrale in Linguistica</li>
                <li>Laurea in Mediatori Linguistici</li>
                <li>Master in Interpretazione Italiano e LIS</li>
              </ul>
            </li>
            <li>Esperienza Internazionale:
              <ul className="list-circle pl-5 mt-1">
                <li>Borsa di studio presso la Gallaudet University di Washington DC</li>
              </ul>
            </li>
            <li>Attività Professionale:
              <ul className="list-circle pl-5 mt-1">
                <li>Docente LIS dal 2006 ad oggi</li>
                <li>Esperto in mediazione linguistica e interpretazione</li>
              </ul>
            </li>
          </ul>
        </>
      ),
      image: antonioDiMarcoImage
    },
    {
      title: "Umberto Stefanini - Docente LIS e Artista",
      content: (
        <>
          <p>Umberto Stefanini nasce da una famiglia di sordi ed è cresciuto nella comunità sorda, portando un bagaglio unico di esperienza e competenze nell'insegnamento e nelle arti.</p>
          <ul className="list-disc pl-5 space-y-2 mt-3">
            <li>Background e Formazione:
              <ul className="list-circle pl-5 mt-1">
                <li>Cresciuto in una famiglia di sordi e nella comunità sorda</li>
                <li>Coordinatore del Circolo Integrazione Sordi di Monterotondo</li>
                <li>Formato come Interprete, Performer e Docente LIS presso AES Accademia Europea Sordi</li>
                <li>Laureato all'Accademia di Belle Arti</li>
              </ul>
            </li>
            <li>Master e Specializzazioni:
              <ul className="list-circle pl-5 mt-1">
                <li>Master in Artiterapie</li>
                <li>Master in Educazione all'immagine e pedagogia della lettura e della scrittura</li>
                <li>Master in Counselor per la relazione d'aiuto</li>
                <li>Master in Minorazioni sensoriali</li>
              </ul>
            </li>
            <li>Attività di Insegnamento:
              <ul className="list-circle pl-5 mt-1">
                <li>Docente di Disegno e Storia dell'arte</li>
                <li>Docente di Discipline grafiche, pittoriche e scenografiche</li>
                <li>Docente di Sostegno</li>
                <li>Docente di Teatro</li>
              </ul>
            </li>
            <li>Qualifiche Professionali:
              <ul className="list-circle pl-5 mt-1">
                <li>Attore e AiutoRegista</li>
                <li>Esperto in turismo ecocompatibile</li>
                <li>Animatore socio-culturale</li>
                <li>Operatore Sociale di Strada</li>
                <li>Esperto in Didattica specializzata per alunni sordi</li>
              </ul>
            </li>
          </ul>
        </>
      ),
      image: umbertoStefaniniImage
    }
  ];

  return (
    <div className="container mx-auto px-4 py-12 dark:bg-gray-900">
      <h1 className="text-4xl font-bold text-aeslis-blue dark:text-yellow-300 mb-8">Chi Siamo</h1>
      
      {/* Introduzione base */}
      <p className="text-lg mb-6 dark:text-yellow-400">
        AES è una scuola dedicata all'insegnamento della Lingua dei Segni Italiana (LIS). 
        La nostra missione è promuovere la cultura sorda e facilitare la comunicazione tra 
        le comunità sorde e udenti.
      </p>
      <p className="text-lg mb-6 dark:text-gray-300">
        Fondata nel 2008 da Benedetto Santarelli il primo Educatore Sordo italiano e Laura Santarelli, AES si è affermata come un punto di riferimento 
        per l'apprendimento della LIS, offrendo corsi di alta qualità per tutti i livelli, 
        dal principiante all'avanzato.
      </p>

      {/* Nuova sezione Mission */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-3xl font-bold text-aeslis-blue dark:text-yellow-300 mb-4">Mission</h2>
        <div className="space-y-4">
          <p className="text-gray-700 dark:text-gray-300">
            L'obiettivo perseguito è di formare Professionisti in grado di favorire l'inclusione 
            e l'integrazione delle persone sorde. La caratteristica peculiare è la realizzazione 
            di una programmazione inclusiva, attenta a supportare le diverse competenze e nello 
            stesso tempo a valorizzare le eccellenze dei singoli partecipanti.
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            Negli anni la Scuola ha maturato una prospettiva volta a progettare contesti di 
            apprendimento che valorizzino un modo di fare formazione attraverso l'illustrazione 
            di situazioni autentiche, significative, attinte dalla vita reale. In questo modo, 
            si conducono gli studenti a scoprire la cultura della comunità delle persone Sorde 
            facendone esperienza concreta e diventando corresponsabili del proprio percorso educativo.
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            Il percorso formativo è, pertanto, costituito dalla sequenza delle esperienze 
            ("situazioni di apprendimento") che mobilitano le risorse intrinseche degli allievi 
            e permettono loro di fare esperienza personale del sapere, nel momento in cui assolvono 
            a compiti reali e significativi e risolvono i problemi che questi compiti via via presentano.
          </p>
        </div>
      </div>

      {/* Il team */}
      <h2 className="text-3xl font-bold mb-6 text-aeslis-blue dark:text-yellow-300">Il nostro Team</h2>
      
      <ExpandableSection {...direttrice} />
      
      <h3 className="text-2xl font-bold mb-4 text-aeslis-blue dark:text-yellow-300">I nostri Docenti</h3>
      {docenti.map((docente, index) => (
        <ExpandableSection key={index} {...docente} />
      ))}
    </div>
  );
};

export default ChiSiamo;